import { Country } from './country.models';
import { GuideNote } from './guideNote.models';

export enum GuideStatus {
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  DRAFT = 'DRAFT',
  ISSUED = 'ISSUED',
  TRANSIT = 'TRANSIT',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  FINALIZED = 'FINALIZED',
}

export enum GuideAccessType {
  DRIVER = 'DRIVER',
  EXPEDITOR = 'EXPEDITOR',
  TRANSPORTER = 'TRANSPORTER',
  ADDRESSEE = 'ADDRESSEE',
}

export class SuccessiveTransports {
  nif: string;
  name: string;
  countryId: number;
  country: Country | null;
  address: string;
  licenseNumber: string;
}

export class GoodsTransporter {
  brand_and_numbers: string;
  no_of_volumes: number;
  gross_weight: number;
  net_weight: number;
  volume: number;
  nature: string;
  packing_method: string;
}

export class DangerousGoods {
  onu_no: string;
  designation: string;
  tags: string;
  packaging_group: string;
  tunnel_restriction_code: string;
}

export class Guide {
  id: string;
  companyId: string;

  guide_number: string;
  internal_reference: string;
  expeditor_nif: string;
  expeditor_name: string;
  expeditor_email: string;
  expeditorCountryId: string;
  expeditorCountry: any;
  expeditor_address: string;
  expeditor_postalCode: string;
  expeditor_locality: string;
  transporter_nif: string;
  transporter_name: string;
  transporter_email: string;
  transporterCountryId: string;
  transporterCountry: any;
  transporter_addreess: string;
  transporter_postalCode: string;
  transporter_locality: string;
  transporter_licenseNumber: string;
  addressee_nif: string;
  addressee_name: string;
  addressee_email: string;
  addresseeCountryId: string;
  addresseeCountry: any;
  addressee_addreess: string;
  addressee_postalCode: string;
  addressee_locality: string;
  loading_place: string;
  unloading_place: string;
  at_code: string;
  special_conditions: string;
  successive_transports_active: boolean;
  successiveTransports: SuccessiveTransports[];

  goodsTransported: GoodsTransporter[];
  dangerous_goods_active: boolean;
  dangerousGoods: DangerousGoods[];

  dispatcher_instructions: string;
  dispatcher_signature: string;
  dispatcher_signature_data: string;
  dispatcher_absent: boolean;
  dispatcher_made_in: string;
  dispatcher_date: Date | null;
  transport_price_dispatcher: number;
  transport_price_addressee: number;
  discounts_dispatcher: number;
  discounts_addressee: number;
  net_price_dispatcher: number;
  net_price_addressee: number;
  supplements_dispatcher: number;
  supplements_addressee: number;
  expenses_dispatcher: number;
  expenses_addressee: number;
  total_dispatcher: number;
  total_addressee: number;

  shipper_vehicle: string;
  shipper_trailer: string;
  shipper_gross_weight: number | null;
  shipper_payload: number | null;
  shipper_notes: string;
  shipper_signature: string;
  shipper_signature_data: string;
  shipper_fuel_price: number | null;
  shipper_notes_accepted: boolean;
  shipperNotes: string;

  receipt_of_goods_notes: string;
  receipt_of_goods_signature: string;
  receipt_of_goods_signature_data: string;
  receipt_of_goods_absent_addressee: boolean;
  receipt_of_goods_place_date: string;
  receipt_of_goods_notes_accepted: boolean;
  receipt_of_goods_file_id: string;
  receipt_of_goods_file: any;

  invoiceNumber: string;
  invoiceDate: Date | null;

  status: GuideStatus;
  canEditGuide: boolean;

  notes: GuideNote[];
  history: any[];

  issued_at: Date | null;
  emmited_at: Date | null;

  createdAt: Date | null;
  updatedAt: Date | null;

  constructor(obj?: any) {
    this.id = obj?.id ?? null;

    this.companyId = obj?.companyId ?? null;

    this.guide_number = obj?.guide_number ?? null;
    this.internal_reference = obj?.internal_reference ?? null;
    this.expeditor_nif = obj?.expeditor_nif ?? null;
    this.expeditor_name = obj?.expeditor_name ?? null;
    this.expeditor_email = obj?.expeditor_email ?? null;
    this.expeditorCountryId = obj?.expeditorCountryId ?? null;
    this.expeditorCountry = obj?.expeditorCountry ? new Country(obj.expeditorCountry) : null;
    this.expeditor_address = obj?.expeditor_address ?? null;
    this.expeditor_postalCode = obj?.expeditor_postalCode ?? null;
    this.expeditor_locality = obj?.expeditor_locality ?? null;
    this.transporter_nif = obj?.transporter_nif ?? null;
    this.transporter_name = obj?.transporter_name ?? null;
    this.transporter_email = obj?.transporter_email ?? null;
    this.transporterCountryId = obj?.transporterCountryId ?? null;
    this.transporterCountry = obj?.transporterCountry ? new Country(obj.transporterCountry) : null;
    this.transporter_addreess = obj?.transporter_addreess ?? null;
    this.transporter_postalCode = obj?.transporter_postalCode ?? null;
    this.transporter_locality = obj?.transporter_locality ?? null;
    this.transporter_licenseNumber = obj?.transporter_licenseNumber ?? null;
    this.addressee_nif = obj?.addressee_nif ?? null;
    this.addressee_name = obj?.addressee_name ?? null;
    this.addressee_email = obj?.addressee_email ?? null;
    this.addresseeCountryId = obj?.addresseeCountryId ?? null;
    this.addresseeCountry = obj?.addresseeCountry ? new Country(obj.addresseeCountry) : null;
    this.addressee_addreess = obj?.addressee_addreess ?? null;
    this.addressee_postalCode = obj?.addressee_postalCode ?? null;
    this.addressee_locality = obj?.addressee_locality ?? null;
    this.loading_place = obj?.loading_place ?? null;
    this.unloading_place = obj?.unloading_place ?? null;
    this.at_code = obj?.at_code ?? null;
    this.special_conditions = obj?.special_conditions ?? null;
    this.successive_transports_active = obj?.successive_transports_active ?? false;
    this.successiveTransports = obj?.successiveTransports ?? [];

    this.goodsTransported = obj?.goodsTransported ?? [];
    this.dangerous_goods_active = obj?.dangerous_goods_active ?? false;
    this.dangerousGoods = obj?.dangerousGoods ?? [];

    this.dispatcher_instructions = obj?.dispatcher_instructions ?? null;
    this.dispatcher_signature = obj?.dispatcher_signature ?? null;
    this.dispatcher_signature_data = obj?.dispatcher_signature_data ?? null;
    this.dispatcher_absent = obj?.dispatcher_absent ?? false;
    this.dispatcher_made_in = obj?.dispatcher_made_in ?? null;
    this.dispatcher_date = obj?.dispatcher_date ? new Date(obj.dispatcher_date) : null;
    this.transport_price_dispatcher = Number(obj?.dispatcher_transport_price_dispatcher ?? 0);
    this.transport_price_addressee = Number(obj?.dispatcher_transport_price_addressee ?? 0);
    this.discounts_dispatcher = Number(obj?.dispatcher_discounts_dispatcher ?? 0);
    this.discounts_addressee = Number(obj?.dispatcher_discounts_addressee ?? 0);
    this.net_price_dispatcher = Number(obj?.dispatcher_net_price_dispatcher ?? 0);
    this.net_price_addressee = Number(obj?.dispatcher_net_price_addressee ?? 0);
    this.supplements_dispatcher = Number(obj?.dispatcher_supplements_dispatcher ?? 0);
    this.supplements_addressee = Number(obj?.dispatcher_supplements_addressee ?? 0);
    this.expenses_dispatcher = Number(obj?.dispatcher_expenses_dispatcher ?? 0);
    this.expenses_addressee = Number(obj?.dispatcher_expenses_addressee ?? 0);
    this.total_dispatcher = Number(obj?.dispatcher_total_dispatcher ?? 0);
    this.total_addressee = Number(obj?.dispatcher_total_addressee ?? 0);

    this.shipper_vehicle = obj?.shipper_vehicle ?? null;
    this.shipper_trailer = obj?.shipper_trailer ?? null;
    this.shipper_gross_weight = obj?.shipper_gross_weight != null ? Number(obj.shipper_gross_weight) : null;
    this.shipper_payload = obj?.shipper_payload != null ? Number(obj.shipper_payload) : null;
    this.shipper_notes = obj?.shipper_notes ?? null;
    this.shipper_signature = obj?.shipper_signature ?? null;
    this.shipper_signature_data = obj?.shipper_signature_data ?? null;
    this.shipper_fuel_price = obj?.shipper_fuel_price != null ? Number(obj.shipper_fuel_price) : null;
    this.shipper_notes_accepted = obj?.shipper_notes_accepted ?? false;
    this.shipperNotes = obj?.shipperNotes ?? [];

    this.receipt_of_goods_notes = obj?.receipt_of_goods_notes ?? null;
    this.receipt_of_goods_signature = obj?.receipt_of_goods_signature ?? null;
    this.receipt_of_goods_signature_data = obj?.receipt_of_goods_signature_data ?? null;
    this.receipt_of_goods_absent_addressee = obj?.receipt_of_goods_absent_addressee ?? false;
    this.receipt_of_goods_place_date = obj?.receipt_of_goods_place_date ?? null;
    this.receipt_of_goods_notes_accepted = obj?.receipt_of_goods_notes_accepted ?? false;
    this.receipt_of_goods_file_id = obj?.receipt_of_goods_file_id ?? null;
    this.receipt_of_goods_file = obj?.receipt_of_goods_file ?? null;

    this.invoiceNumber = obj?.invoiceNumber ?? null;
    this.invoiceDate = obj?.invoiceDate ? new Date(obj.invoiceDate) : null;

    this.status = obj?.status ?? GuideStatus.PROCESSING;
    this.canEditGuide = obj?.canEditGuide ?? true;

    this.notes = obj?.notes ?? [];
    this.history = obj?.history ?? [];

    this.issued_at = obj?.issued_at ? new Date(obj.issued_at) : null;
    this.emmited_at = obj?.emmited_at ? new Date(obj.emmited_at) : null;

    this.createdAt = obj?.createdAt ? new Date(obj.createdAt) : null;
    this.updatedAt = obj?.updatedAt ? new Date(obj.updatedAt) : null;
  }

  getStatusDescription() {
    switch (this.status) {
      case GuideStatus.PROCESSING: {
        return 'Pendente Liquidação';
      }
      case GuideStatus.PENDING: {
        return 'Disponível';
      }
      case GuideStatus.DRAFT: {
        return 'Em Elaboração';
      }
      case GuideStatus.ISSUED: {
        return 'Emitida';
      }
      case GuideStatus.TRANSIT: {
        return 'Em Trânsito';
      }
      case GuideStatus.DELIVERED: {
        return 'Entregue';
      }
      case GuideStatus.CANCELLED: {
        return 'Cancelada';
      }
      case GuideStatus.FINALIZED: {
        return 'Faturada';
      }
      default: {
        return '';
      }
    }
  }

  getStatusColor() {
    switch (this.status) {
      case GuideStatus.PROCESSING: {
        return '194,194,194';
      }
      case GuideStatus.PENDING: {
        return '255,244,128';
      }
      case GuideStatus.DRAFT: {
        return '243,169,58';
      }
      case GuideStatus.ISSUED: {
        return '128,255,142';
      }
      case GuideStatus.TRANSIT: {
        return '100,207,237';
      }
      case GuideStatus.DELIVERED: {
        return '74,140,255';
      }
      case GuideStatus.CANCELLED: {
        return '194,194,194';
      }
      case GuideStatus.FINALIZED: {
        return '74,140,255';
      }
      default: {
        return '194,194,194';
      }
    }
  }
}
