import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CompanyService } from '../core/database/company.service';
import { OfflineService } from '../core/service/offline.service';
import { SystemConfigurationService } from '../core/database/system-configuration.service';
import { UserCompaniesService } from '../core/database/user-companies.service';
import { UserService } from '../core/database/user.service';
import { AuthService } from '../core/service/auth.service';
import { UtilsService } from '../core/service/utils.service';
import moment from 'moment';
import { Company } from '../core/database/models/company.models';
import { User } from '../core/database/models/auth.models';
import { GuideService } from '../core/database/guide.service';
import { Platform } from '@angular/cdk/platform';
import { PwaService } from '../core/service/pwa.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('slideOut') slideOut: ElementRef;
  sidenav: any = null;
  @ViewChild('modalRef') modalRef: ElementRef;
  modal: any = null;

  currentUser: User | null = null;
  currentCompany: Company | null = null;

  version = environment.version;

  syncLoading: boolean = false;
  showLoadingMessage: boolean = false;

  deferredPrompt: any = null;
  showInstallBanner: boolean = false;

  syncDataInterval: any;
  offlineCheckInterval: any;
  isOnline: boolean = true;

  constructor(
    protected authService: AuthService,
    protected userService: UserService,
    protected companyService: CompanyService,
    protected userCompaniesService: UserCompaniesService,
    protected guideService: GuideService,
    protected systemConfigurationService: SystemConfigurationService,
    private router: Router,
    readonly pwaService: PwaService,
    readonly offlineService: OfflineService,
    public utils: UtilsService
  ) {
    this.deferredPrompt = this.pwaService.deferredPrompt;
    this.showInstallBanner = this.pwaService.showInstallBanner;
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUser;
    this.currentCompany = this.authService.currentCompany;

    if (!environment.production) {
      this.version = environment.version + ' - Dev';
    }
  }

  ngAfterViewInit() {
    this.sidenav = M.Sidenav.init(this.slideOut?.nativeElement);

    if (!this.currentUser) {
      this.authService.logout().subscribe();
      return;
    }

    if (!this.currentUser.photo || this.currentUser.photo == '') {
      this.currentUser.photo = this.utils.getInitialsAvatar();
    }

    if (!this.currentCompany) {
      this.router.navigate(['select-company']);
    }

    this.setIntervals();
    this.listenToEvents();
  }

  ngOnDestroy() {
    clearInterval(this.syncDataInterval);
    clearInterval(this.offlineCheckInterval);
  }

  async onInstallPwa() {
    if (!this.deferredPrompt) {
      console.warn('deferred prompt is null [Website cannot be installed]');
      this.showInstallBanner = false;
      return;
    }

    this.deferredPrompt.prompt();

    const { outcome } = await this.deferredPrompt.userChoice;

    if (outcome === 'accepted') {
      this.deferredPrompt = null;
      this.showInstallBanner = false;
    }
  }
  async onRefusePwa() {
    this.showInstallBanner = false;
    return;
  }

  private setIntervals() {
    this.syncData();
    this.syncDataInterval = setInterval(() => {
      this.syncData();
    }, 0.5 * 60_000);

    this.offlineCheckInterval = setInterval(() => {
      this.offlineCheck();
    }, 1 * 60_000);
  }

  private listenToEvents() {
    this.authService.companyChangedListenner.subscribe((currentCompany) => {
      this.syncData();
    });

    this.isOnline = this.utils.isOnline();
    this.offlineService.connectionChanged.subscribe((isOnline) => {
      this.isOnline = isOnline;

      if (isOnline) {
        this.syncData();
        if (localStorage.getItem('offlineTime')) {
          localStorage.removeItem('offlineTime');
        }
      } else {
        localStorage.setItem('offlineTime', moment().toISOString());
      }
    });
  }

  offlineCheck() {
    if (this.utils.isOnline()) {
      return;
    }

    const offlineTime = localStorage.getItem('offlineTime');
    if (offlineTime) {
      const now = moment();
      const diff = now.diff(moment(offlineTime), 'hours');
      if (diff >= 2) {
        this.utils.toast(`Está offline à mais de 2 horas e poderá perder registos.`);
        localStorage.setItem('offlineTime', moment().toISOString());
      }
    }
  }

  async syncData() {
    this.authService
      .syncData()
      .then(() => {})
      .catch((error) => {
        console.warn(`LayoutComponent ~ syncData:`, error);
      })
      .finally(() => (this.syncLoading = false));
  }

  onSidenavOpen() {
    this.sidenav.open();
  }

  goToHome() {
    // this.syncData();
    return this.router.navigate(['home']);
  }

  onHome() {
    this.sidenav.close();
    return this.goToHome();
  }

  onChangeCompany() {
    this.sidenav.close();
    return this.router.navigate(['select-company']);
  }

  onGuides() {
    this.sidenav.close();
    return this.router.navigate(['guides']);
  }

  onHelpPage() {
    this.sidenav.close();
    return this.router.navigate(['help']);
  }

  onPrivacyPolicy() {
    this.sidenav.close();
    return this.router.navigate(['privacy-policy']);
  }

  onExport() {
    this.sidenav.close();
    return this.router.navigate(['export']);
  }

  onLogout() {
    this.sidenav.close();
    this.authService.logout().subscribe();
  }

  onProfile() {
    this.sidenav.close();
    return this.router.navigate(['profile']);
  }
}
