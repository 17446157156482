import { Country } from './country.models';

export enum CompanyStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  ANALYSIS = 'ANALYSIS',
  DELETED = 'DELETED',
  CONSULT = 'CONSULT',
}

export enum AntramSIStatus {
  CHECK = 'CHECK',
  NOTCHECK = 'NOTCHECK',
  LOADING = 'LOADING',
}

export enum AlertRenovationEnum {
  SHOW = 'SHOW',
  NOTSHOW = 'NOTSHOW',
  LOADING = 'LOADING',
}

export enum EntityTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  COLLECTIVE = 'COLLECTIVE',
}

export enum GuideEntityType {
  EXPEDITOR = 'EXPEDITOR',
  TRANSPORTER = 'TRANSPORTER',
  ADDRESSEE = 'ADDRESSEE',
  DRIVER = 'DRIVER',
}

export class Company {
  id: string;

  nif: string | null;
  name: string | null;
  email: string | null;
  contact: string | null;
  address: string | null;
  locality: string | null;
  postalCode: string | null;
  countryId: number;
  country: Country | null;
  is_hq: boolean;
  hq_address: string | null;
  hq_locality: string | null;
  hq_postalCode: string | null;
  hqCountryId: number;
  hqCountry: Country | null;
  status: CompanyStatus;
  licensesToRequest: number | null;
  entityType: EntityTypes;
  isDemo: boolean;
  isPassiveSubject: boolean;
  isProtocol: boolean;
  isVatIsention: boolean;
  ignoreAnualSubscription: boolean;
  isTransporter: boolean;
  isShipper: boolean;
  licenseNumber: string | null;

  rittSubscribed: boolean;
  eGuidesSubscribed: boolean;

  createdById: string | null;
  updatedById: string | null;
  approvedById: string | null;
  partnerId: string | null;
  renewalDate: Date | null;

  createdAt: Date | null;
  updatedAt: Date | null;

  constructor(obj?: any) {
    this.id = obj?.id ?? '';

    this.nif = obj?.nif ?? null;
    this.name = obj?.name ?? null;
    this.email = obj?.email ?? null;
    this.contact = obj?.contact ?? null;
    this.address = obj?.address ?? null;
    this.locality = obj?.locality ?? null;
    this.postalCode = obj?.postalCode ?? null;
    this.countryId = obj?.countryId ?? null;
    this.country = obj?.country ? new Country(obj.country) : null;
    this.is_hq = obj?.is_hq ?? false;
    this.hq_address = obj?.hq_address ?? null;
    this.hq_locality = obj?.hq_locality ?? null;
    this.hq_postalCode = obj?.hq_postalCode ?? null;
    this.hqCountryId = obj?.hqCountryId ?? null;
    this.hqCountry = obj?.hqCountry ? new Country(obj.hqCountry) : null;
    this.status = obj?.status ?? CompanyStatus.PENDING;
    this.licensesToRequest = obj?.licensesToRequest ?? null;
    this.entityType = obj?.entityType ?? null;
    this.isDemo = obj?.isDemo ?? false;
    this.isTransporter = obj?.isTransporter ?? false;
    this.isShipper = obj?.isShipper ?? false;
    this.licenseNumber = obj?.licenseNumber ?? false;
    this.isVatIsention = obj?.isVatIsention ?? false;
    this.isProtocol = obj?.isProtocol ? obj.isProtocol : false;
    this.isPassiveSubject = obj?.isPassiveSubject ?? false;
    this.ignoreAnualSubscription = obj?.ignoreAnualSubscription ?? false;

    this.rittSubscribed = obj?.rittSubscribed ?? false;
    this.eGuidesSubscribed = obj?.eGuidesSubscribed ?? false;

    this.createdById = obj?.createdById ?? obj?.createdBy?.id ?? null;
    this.updatedById = obj?.updatedById ?? obj?.updatedBy?.id ?? null;
    this.approvedById = obj?.approvedById ?? obj?.approvedBy?.id ?? null;
    this.partnerId = obj?.partnerId ?? obj?.partner?.id ?? null;
    this.renewalDate = obj?.renewalDate ? new Date(obj.renewalDate) : null;

    this.createdAt = obj?.createdAt ? new Date(obj.createdAt) : null;
    this.updatedAt = obj?.updatedAt ? new Date(obj.updatedAt) : null;
  }
}
